import { createSlice, PayloadAction, ActionReducerMapBuilder, Draft } from '@reduxjs/toolkit'
import moment from 'moment'

import { ReservableSpaceScreen } from 'components/organisms/Reservable'
import { Booking, Reservable, Space, RepeatType as MobileScheduleRepeatType } from 'graphql/autogenerate/schemas'
import { getAWSFormatDate, getConvertedEngLocaleDate } from 'helpers'
import { MainEmployeeData } from 'types/data.types'

import { fetchCurrentBookingById, fetchReservableById } from './editBookingThunks'
import { initialStateProps } from '../types'

import type { FormValues } from 'components/shared/widgets/CalendarInfoDialog/types'

type SelectWeekNumberType = {
    weekNumber: number
    year: number
}

const initialState: initialStateProps = {
    date: getAWSFormatDate(moment()),
    year: moment().year(),
    weekNumber: moment().isoWeek(),
    scheduleBookings: [],
    placeBookings: [],
    reservables: [],
    open: false,
    startWorkTime: '08:00',
    endWorkTime: '24:00',
    employeeID: '',
    currentReservable: null,
    suggestedSeat: null,
    loadingTimeLine: false,
    loadingBookingInfo: false,
    currentSpace: null,
    bookingRepeatType: MobileScheduleRepeatType.NEVER,
    repeatDaysOfWeek: [getConvertedEngLocaleDate(moment(getAWSFormatDate(moment())), 'dddd')],
    selectedTeamEventMembers: [],
    selectedEmployee: null,
    checkInStatus: false,
    currentBooking: null,
    editFormValue: null,
    parkingMapStatus: false,
    currentParkingReservable: null,
    suggestedSeatID: null,
    repeatDate: [],
    isSpaceMapLoading: false,
    spaceLoader: false,
    selectedBookingTime: ['00:00', '24:00'],
    availableTimeForBooking: ['00:00', '24:00'],
    isSeatAvailable: true,
    editBookingPopup: false,
    isEditMode: false,
    isParking: false,
    isNotAvailableSeatReason: '',
}

const editBookingSlice = createSlice({
    name: 'editBooking',
    initialState,
    reducers: {
        init: (state, action: PayloadAction<{ employeeID: string }>) => {
            return {
                ...state,
                employeeID: action.payload.employeeID,
            }
        },

        selectCurrentReservable: (state, action: PayloadAction<ReservableSpaceScreen | null>) => {
            return {
                ...state,
                currentReservable: action.payload,
            }
        },

        selectSuggestedSeat: (state, action: PayloadAction<ReservableSpaceScreen | null>) => {
            return {
                ...state,
                suggestedSeat: action.payload,
            }
        },

        selectDate: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                date: action.payload,
            }
        },

        selectWeekNumber: (state, action: PayloadAction<SelectWeekNumberType>) => {
            return {
                ...state,
                weekNumber: action.payload.weekNumber,
                year: action.payload.year,
            }
        },

        setExistingBookings: (state, action: PayloadAction<Array<Booking>>) => {
            return {
                ...state,
                scheduleBookings: action.payload,
                open: true,
                checkInStatus: true,
            }
        },

        setSpace: (state, action: PayloadAction<Space>) => {
            return {
                ...state,
                startWorkTime: action.payload.workingHoursFrom,
                endWorkTime: action.payload.workingHoursTo,
                currentSpace: action.payload,
                currentReservable:
                    state.currentSpace?.officeID !== action.payload.officeID ? null : state.currentReservable, // reset previous selected reservable because it's related to the previous Office
            }
        },

        setReservables: (state, action: PayloadAction<Array<ReservableSpaceScreen>>) => {
            return {
                ...state,
                reservables: action.payload,
            }
        },

        switchEditBooking: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                open: action.payload,
            }
        },

        setLoadingTimeLine: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                loadingTimeLine: action.payload,
            }
        },

        setSpaceLoading: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                spaceLoader: action.payload,
            }
        },

        setLoadingBookingInfo: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                loadingBookingInfo: action.payload,
            }
        },

        setSpaceMapLoading: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isSpaceMapLoading: action.payload,
            }
        },

        setCheckInStatus: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                checkInStatus: action.payload,
            }
        },

        setBookingRepeatType: (state, action: PayloadAction<MobileScheduleRepeatType>) => {
            return {
                ...state,
                bookingRepeatType: action.payload,
                repeatDaysOfWeek: [getConvertedEngLocaleDate(moment(state.date), 'dddd')],
            }
        },

        setParking: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isParking: action.payload,
            }
        },

        setRepeatDaysOfWeek: (state, action: PayloadAction<Array<string>>) => {
            return {
                ...state,
                repeatDaysOfWeek: action.payload,
            }
        },

        setTeamEventMembers: (state, action: PayloadAction<Array<MainEmployeeData>>) => {
            return {
                ...state,
                selectedTeamEventMembers: action.payload,
            }
        },

        setCurrentBooking: (state, action: PayloadAction<Booking | null>) => {
            const timeStart = moment(action.payload?.startTime || state.startWorkTime).format('HH:mm')
            const timeEnd = moment(action.payload?.endTime || state.endWorkTime).format('HH:mm')
            const reservable = action.payload?.reservable

            const newState: initialStateProps = {
                ...state,
                currentBooking: action.payload,
                selectedBookingTime: [timeStart, timeEnd],
            }

            if (reservable && action.payload?.bookingType === 'OFFICE') {
                const convertedReservable = {
                    name: reservable.name,
                    availabilityType: reservable.availabilityType,
                    id: reservable.id,
                    x: reservable.x,
                    y: reservable.y,
                    typeReservable: reservable.type,
                    nameRoom: reservable.name,
                    zonePoints: reservable?.zonePoints,
                    color: reservable?.color,
                    space: (reservable?.Space as Space) || action.payload?.Space || null,
                    availabilityByDatesAndTime: reservable.availabilityByDatesAndTime,
                }
                newState.currentReservable = {
                    ...convertedReservable,
                } as unknown as ReservableSpaceScreen
            } else if (action.payload?.bookingType !== 'OFFICE') {
                newState.currentReservable = null
            }

            if (action?.payload?.BookingRequest?.repeatType) {
                newState.bookingRepeatType = action.payload?.BookingRequest?.repeatType
                newState.repeatDaysOfWeek = [getConvertedEngLocaleDate(moment(state.date), 'dddd')]

                if (action.payload?.BookingRequest?.daysOfWeek) {
                    newState.repeatDaysOfWeek = action.payload?.BookingRequest?.daysOfWeek
                }

                if (action.payload?.BookingRequest?.dates) {
                    newState.repeatDate = action.payload?.BookingRequest?.dates
                }
            } else {
                newState.bookingRepeatType = MobileScheduleRepeatType.NEVER
                if (action.payload?.BookingRequest?.daysOfWeek) {
                    newState.repeatDaysOfWeek = []
                }
                if (action.payload?.BookingRequest?.dates) {
                    newState.repeatDate = []
                }
            }

            return newState
        },

        setFormEdit: (state, action: PayloadAction<FormValues>) => {
            return {
                ...state,
                editFormValue: action.payload,
            }
        },

        setEdit: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isEditMode: action.payload,
            }
        },

        // setMapStatus: (state, action: PayloadAction<boolean>) => {
        //     return {
        //         ...state,
        //         openMap: action.payload,
        //     }
        // },
        //
        // setEditBookingPopup: (state, action: PayloadAction<boolean>) => {
        //     return {
        //         ...state,
        //         editBookingPopup: action.payload,
        //     }
        // },

        selectRepeatDate: (state, action: PayloadAction<Array<string>>) => {
            return {
                ...state,
                repeatDate: action.payload,
            }
        },

        setSelectedEmployee: (state, action: PayloadAction<MainEmployeeData | null>) => {
            return {
                ...state,
                selectedEmployee: action.payload,
            }
        },

        setBookingFromPlace: (state, action: PayloadAction<Array<Booking>>) => {
            return {
                ...state,
                placeBookings: action.payload,
            }
        },

        setIsSeatAvailable: (
            state,
            action: PayloadAction<{ isSeatAvailable: boolean; isNotAvailableSeatReason: string }>,
        ) => ({
            ...state,
            isSeatAvailable: action.payload.isSeatAvailable,
            isNotAvailableSeatReason: action.payload.isNotAvailableSeatReason,
        }),

        /*Parking*/
        selectCurrentReservableParking: (state, action: PayloadAction<ReservableSpaceScreen | null>) => {
            return {
                ...state,
                currentParkingReservable: action.payload,
            }
        },

        setParkingMap: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                parkingMapStatus: action.payload,
            }
        },

        reset: (state) => ({
            ...initialState,
            // don't reset working time
            employeeID: state.employeeID,
            startWorkTime: state.startWorkTime,
            endWorkTime: state.endWorkTime,
            date: state.date,
        }),
    },
    extraReducers: (builder: ActionReducerMapBuilder<initialStateProps>) => {
        builder
            .addCase(fetchCurrentBookingById.pending, (state: Draft<initialStateProps>) => {
                return {
                    ...state,
                    loadingTimeLine: true,
                }
            })
            .addCase(
                fetchCurrentBookingById.fulfilled,
                (state: Draft<initialStateProps>, action: PayloadAction<Booking>) => {
                    const formattedDate = moment(action.payload.startTime).format('YYYY-MM-DD') // Формат даты: ГГГГ-ММ-ДД
                    return {
                        ...state,
                        currentBooking: action.payload,
                        date: formattedDate,
                    }
                },
            )
            .addCase(fetchCurrentBookingById.rejected, (state: Draft<initialStateProps>, action) => {
                console.error('Failed to fetch reservable:', action.error.message)
                return {
                    ...state,
                }
            })

        builder
            .addCase(fetchReservableById.pending, (state: Draft<initialStateProps>) => {
                return {
                    ...state,
                    loadingTimeLine: true,
                }
            })
            .addCase(
                fetchReservableById.fulfilled,
                (state: Draft<initialStateProps>, action: PayloadAction<Reservable>) => {
                    const bookings = action.payload?.BookingsByDateAndDayOfWeek?.items
                    const filteredBookings = bookings?.filter(
                        (booking) => booking?.Employee?.id !== state?.employeeID,
                    ) as Booking[]
                    return {
                        ...state,
                        placeBookings: filteredBookings,
                        loadingTimeLine: false,
                    }
                },
            )
            .addCase(fetchReservableById.rejected, (state: Draft<initialStateProps>, action) => {
                console.error('Failed to fetch reservable:', action.error.message)
                return {
                    ...state,
                    loadingTimeLine: false,
                }
            })
    },
})

export const editBookingActions = editBookingSlice.actions
export default editBookingSlice.reducer

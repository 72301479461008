import createTheme from '@material-ui/core/styles/createMuiStrictModeTheme'

declare module '@material-ui/core/styles/createTheme' {
    interface Theme {
        color: {
            darkBlue: React.CSSProperties['color']
            black: React.CSSProperties['color']
            gray1: React.CSSProperties['color']
            gray2: React.CSSProperties['color']
            gray3: React.CSSProperties['color']
            gray5: React.CSSProperties['color']
            gray6: React.CSSProperties['color']
            white: React.CSSProperties['color']
            coral: React.CSSProperties['color']
            orange: React.CSSProperties['color']
            green: React.CSSProperties['color']
            blackBackground: React.CSSProperties['color']
            greyBackground: React.CSSProperties['color']
            activeSpacePanel: React.CSSProperties['color']
            blueButton: React.CSSProperties['color']
            black80: React.CSSProperties['color']
            secondaryBlack: React.CSSProperties['color']
            secondaryWhite: React.CSSProperties['color']
            white_80: React.CSSProperties['color']
            white_30: React.CSSProperties['color']
            main_black: React.CSSProperties['color']
            secondary_white_15: React.CSSProperties['color']
            main_blue_40: React.CSSProperties['color']
            secondary_black_25: React.CSSProperties['color']
            secondary_black_50: React.CSSProperties['color']
            negative_red_80: React.CSSProperties['color']
            background_grey: React.CSSProperties['color']
            secondary_white_50: React.CSSProperties['color']
            main_blue_accent: React.CSSProperties['color']
        }
    }
    interface ThemeOptions {
        color: Theme['color']
    }
}

declare module '@material-ui/core/styles/createPalette' {
    interface Palette {
        neutral: Palette['primary']
    }
    interface PaletteOptions {
        neutral: PaletteOptions['primary']
    }
}

const theme = createTheme({
    color: {
        darkBlue: '#5044D6',
        black: '#333',
        gray1: '#707683',
        gray2: '#929DAD',
        gray3: '#A7B4C7',
        gray5: '#ECF0F5',
        gray6: '#F8F8F8',
        white: '#FFFFFF',
        coral: '#FF6868',
        orange: '#FF5525',
        green: '#1BD665',
        blackBackground: '#09090A',
        greyBackground: '#E8E8E8',
        activeSpacePanel: '#0019FF',
        blueButton: '#0019FF',
        black80: 'rgba(51, 51, 51, 0.8)',
        editor_grey: '#e8e8e8',
        greyBackground50: 'rgba(227, 232, 238, 0.50)',
        secondaryBlack: 'rgba(51, 51, 51, 0.5)',
        secondaryWhite: 'rgba(255, 255, 255, 0.3)',
        white_80: 'rgba(255, 255, 255, 0.80)',
        white_30: 'rgba(255, 255, 255, 0.80)',
        main_black: '#333',
        secondary_white_15: 'rgba(255, 255, 255, 0.15)',
        main_blue_40: 'rgba(0, 25, 255, 0.40)',
        secondary_black_25: 'rgba(51, 51, 51, 0.25)',
        secondary_black_50: 'rgba(51, 51, 51, 0.5)',
        negative_red_80: 'rgba(255, 104, 104, 0.8)',
        background_grey: '#E3E8EE',
        main_white_90: 'rgba(255, 255, 255, 0.9)',
        secondary_white_50: 'rgba(255, 255, 255, 0.5)',
        main_blue_accent: '#0019ff',
        background_grey_50: 'rgba(227, 232, 238, 0.5)',
    },
    breakpoint: {
        tablet: 1280,
        mobile: 780,
    },
    palette: {
        primary: {
            main: '#5446EA',
        },
        secondary: {
            main: '#f44336',
        },
        neutral: {
            main: '#5044D6',
        },
    },
    typography: {
        fontFamily: '"Poppins", sans-serif',
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                body: {
                    fontSize: '14px',
                    color: '#333',
                },
                h1: {
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: 24,
                    lineHeight: '36px',
                    color: '#333',
                    marginBottom: 24,
                },
                '.MuiTextField-root': {
                    '& .MuiFormLabel-root': {
                        fontSize: 12,
                        lineHeight: '18px',

                        '&:hover': {
                            borderColor: '#ECF0F5 !important',
                        },
                    },
                },
                'button.MuiButton-root': {
                    borderRadius: 2,
                    textTransform: 'none',
                },
                // Inputs override
                '.MuiFormControl-root': {
                    '& label': {
                        fontSize: '12px',
                        lineHeight: '18px',
                    },
                    '& .MuiSelect-select': {
                        '&:focus': {
                            background: '#fff',
                        },
                    },
                    '& .MuiSelect-icon': {
                        top: 'calc(50% - 8px)',
                        right: '14px',
                    },
                    '& svg': {
                        width: '0.7em',
                        height: '0.7em',
                        color: '#A7B4C7',
                    },
                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderWidth: '1px',
                    },
                    '& .MuiOutlinedInput-inputMarginDense': {
                        padding: '11.7px 14px',
                    },
                    '& .MuiInputBase-root': {
                        fontSize: '14px',

                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#ECF0F5',
                        },

                        '&:hover': {
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#5446EA',
                            },
                        },
                    },
                    // '& input': {
                    //     '&:-internal-autofill-selected, :-webkit-autofill, :-webkit-autofill:hover, :-webkit-autofill:focus, :-webkit-autofill:active': {
                    //         backgroundColor: '#fff !important',
                    //         color: '#192A3E',
                    //         appearance: 'none',
                    //         '-webkit-box-shadow': '0 0 0 30px white inset !important',
                    //     },
                    // },
                    // '& select:-internal-autofill-selected': {
                    //     bacgroundColor: '#fff !important',
                    //     color: '#192A3E',
                    //     appearance: 'none',
                    //     '-webkit-box-shadow': '0 0 0 30px white inset !important',
                    // },
                },
                '.MuiAutocomplete-root': {
                    '& .MuiAutocomplete-endAdornment': {
                        top: 'calc(50% - 11px)',
                    },
                    '& svg': {
                        width: '0.7em',
                        height: '0.7em',
                        color: '#A7B4C7',
                    },
                    '& .MuiInputBase-root': {
                        height: '40px',
                    },
                },
            },
        },
    },
    size: {
        heightContainerScroll: 'calc(100vh - 240px)',
    },
})

export default theme

import { gql } from '@apollo/client'

import { MAIN_EMPLOYEE } from 'graphql/dataQuery'

const BOOKING = gql`
    ${MAIN_EMPLOYEE}
    fragment BookingFieldsForCalendar on Booking {
        id
        startTime
        endTime
        reservableID
        isBlocked
        isFullDay
        bookingType
        bookingSubType
        isTeamEvent
        checkInStatus

        Employee {
            ...MainEmployeeFields
        }

        Space {
            id
            name
            floorPlanFilePath
            workingHoursFrom
            workingHoursTo
            totalSeats
            availableParkingSlots
            officeID
            totalRooms
            Office {
                id
                name
            }
        }

        reservable {
            id
            type
            x
            y
            zonePoints
            color
        }
        BookingRequest {
            id
            repeatType
            addParkingSlot
            daysOfWeek
            ParkingSlot {
                id
                name
                type
                y
                x
                type
            }
        }
    }
`

export const QUERY_LIST_SCHEDULE_BOOKINGS = gql`
    ${BOOKING}
    query ListBookingsByEmployeeAndStartTime($employeeID: ID!, $startTime: String, $endTime: String) {
        listBookingsByEmployeeAndStartTime(
            employeeID: $employeeID
            startTime: { ge: $startTime }
            filter: { endTime: { le: $endTime } }
        ) {
            items {
                ...BookingFieldsForCalendar
            }
        }
    }
`

export const QUERY_GET_BOOKING_BY_ID = gql`
    ${BOOKING}
    query GetBookingByIDWithTeamEventRequest($bookingID: ID!) {
        getBooking(id: $bookingID) {
            ...BookingFieldsForCalendar
            TeamEventRequest {
                owner
                id
                companyID
                Space {
                    companyID
                    id
                    address
                }
                id
                startTime
                endTime
                reservableID
                repeatType
                daysOfWeek
                Reservable {
                    id
                    type
                    x
                    y
                    zonePoints
                    color
                }
            }
        }
    }
`

export const QUERY_PREVIEW_SPACE_DATA = gql`
    ${MAIN_EMPLOYEE}
    query ListReservablesWithBookingsForPreview($spaceID: ID!, $startDate: String!) {
        listReservablesBySpaceAndType(spaceID: $spaceID, type: { between: ["SEAT", "ZONE"] }, limit: 1000) {
            items {
                id
                name
                x
                y
                type
                availabilityType
                maxAmountOfPeople
                Employee {
                    ...MainEmployeeFields
                }
                Department {
                    name
                }
                zonePoints
                color
                ReservableToDepartments {
                    items {
                        Department {
                            id
                            name
                        }
                    }
                }

                BookingsByDateAndDayOfWeek(limit: 1000, dateDayOfWeek: { beginsWith: { date: $startDate } }) {
                    items {
                        id
                        startTime
                        endTime
                        isFullDay
                        isBlocked
                        date
                        employeeID
                        Employee {
                            ...MainEmployeeFields
                        }
                    }
                }
            }
        }
    }
`

export const QUERY_SCHEDULE_SPACE_DATA = gql`
    ${MAIN_EMPLOYEE}
    query ListReservablesWithBookingsBySpaceAndType(
        $spaceID: ID!
        $employee: [EmployeesWithDepartments]!
        $dates: [AWSDate]!
        $bookingRepeatType: RepeatType
        $daysOfWeek: [String]
        $startTime: String
        $endTime: String
        $dateFilter: ModelBookingByReservableAndDateAndDayOfWeekCompositeKeyConditionInput
        $filter: ModelBookingFilterInput
        $filterByUser: ModelReservableFilterInput
        $type: ModelStringKeyConditionInput
    ) {
        listReservablesBySpaceAndType(spaceID: $spaceID, filter: $filterByUser, type: $type, limit: 1000) {
            items {
                id
                name
                x
                y
                type
                availabilityType
                maxAmountOfPeople
                zonePoints
                color
                Employee {
                    ...MainEmployeeFields
                }

                Department {
                    name
                }

                Tags {
                    items {
                        Tag {
                            id
                            name
                        }
                    }
                }

                ReservableToDepartments {
                    items {
                        Department {
                            id
                            name
                        }
                    }
                }

                availabilityByDatesAndTime(
                    bookingRepeatType: $bookingRepeatType
                    employees: $employee
                    dates: $dates
                    daysOfWeek: $daysOfWeek
                    startTime: $startTime
                    endTime: $endTime
                )

                BookingsByDateAndDayOfWeek(limit: 1000, dateDayOfWeek: $dateFilter, filter: $filter) {
                    items {
                        id
                        startTime
                        endTime
                        isFullDay
                        isBlocked
                        employeeID
                        date
                        createdAt
                        Employee {
                            ...MainEmployeeFields
                        }
                    }
                }
            }
        }
    }
`

export const QUERY_SCHEDULE_SPACE_SEAT_AVAILABLE_DATA = gql`
    ${MAIN_EMPLOYEE}
    query ListReservablesWithBookingsBySpace(
        $spaceID: ID!
        $employee: [EmployeesWithDepartments]!
        $dates: [AWSDate]!
        $bookingRepeatType: RepeatType
        $daysOfWeek: [String]
        $startTime: String
        $endTime: String
        $dateFilter: ModelBookingByReservableAndDateAndDayOfWeekCompositeKeyConditionInput
        $filter: ModelBookingFilterInput
        $filterByUser: ModelReservableFilterInput
        $type: ModelStringKeyConditionInput
    ) {
        listReservablesBySpaceAndType(spaceID: $spaceID, filter: $filterByUser, type: $type, limit: 1000) {
            items {
                id
                name
                x
                y
                type
                availabilityType
                maxAmountOfPeople
                zonePoints
                color
                Employee {
                    ...MainEmployeeFields
                }

                Department {
                    name
                }

                Tags {
                    items {
                        Tag {
                            id
                            name
                        }
                    }
                }

                ReservableToDepartments {
                    items {
                        Department {
                            id
                            name
                        }
                    }
                }

                availabilityByDatesAndTime(
                    bookingRepeatType: $bookingRepeatType
                    employees: $employee
                    dates: $dates
                    daysOfWeek: $daysOfWeek
                    startTime: $startTime
                    endTime: $endTime
                )

                BookingsByDateAndDayOfWeek(limit: 1000, dateDayOfWeek: $dateFilter, filter: $filter) {
                    items {
                        id
                        startTime
                        endTime
                        isFullDay
                        isBlocked
                        employeeID
                        Employee {
                            ...MainEmployeeFields
                        }
                    }
                }
            }
        }
    }
`

export const QUERY_SCHEDULE_SPACE_BY_ID_DATA_SUBSCRIBE = gql`
    ${MAIN_EMPLOYEE}
    query getByIdReservablesWithBookingsBySpace(
        $id: ID!
        $employee: [EmployeesWithDepartments]!
        $dates: [AWSDate]!
        $bookingRepeatType: RepeatType
        $daysOfWeek: [String]
        $startTime: String
        $endTime: String
        $dateFilter: ModelBookingByReservableAndDateAndDayOfWeekCompositeKeyConditionInput
        $filter: ModelBookingFilterInput
        $parkingFilter: ModelBookingReservableFilterInput
    ) {
        getReservable(id: $id) {
            id
            name
            x
            y
            type
            availabilityType
            maxAmountOfPeople
            zonePoints
            color
            Employee {
                ...MainEmployeeFields
            }

            Department {
                name
            }

            Tags {
                items {
                    Tag {
                        id
                        name
                    }
                }
            }

            ReservableToDepartments {
                items {
                    Department {
                        id
                        name
                    }
                }
            }

            availabilityByDatesAndTime(
                bookingRepeatType: $bookingRepeatType
                employees: $employee
                dates: $dates
                daysOfWeek: $daysOfWeek
                startTime: $startTime
                endTime: $endTime
            )

            BookingReservablesByCreatedAt(filter: $parkingFilter, limit: 1000) {
                items {
                    booking {
                        startTime
                        endTime
                        isFullDay
                        isBlocked
                        employeeID
                        reservable {
                            type
                            id
                            name
                        }
                        Employee {
                            ...MainEmployeeFields
                        }
                    }
                }
            }

            BookingsByDateAndDayOfWeek(limit: 1000, dateDayOfWeek: $dateFilter, filter: $filter) {
                items {
                    id
                    startTime
                    endTime
                    isFullDay
                    isBlocked
                    employeeID
                    Employee {
                        ...MainEmployeeFields
                    }
                }
            }
        }
    }
`

export const QUERY_SCHEDULE_SPACE_DATA_PARKING = gql`
    ${MAIN_EMPLOYEE}
    query ListParkingSpotsWithBookingsBySpace(
        $spaceID: ID!
        $employee: [EmployeesWithDepartments]!
        $dates: [AWSDate]!
        $bookingRepeatType: RepeatType
        $daysOfWeek: [String]
        $startTime: String
        $endTime: String
        $filterByUser: ModelReservableFilterInput
        $type: ModelStringKeyConditionInput
        $parkingFilter: ModelBookingReservableFilterInput
    ) {
        listReservablesBySpaceAndType(spaceID: $spaceID, filter: $filterByUser, type: $type, limit: 1000) {
            items {
                id
                name
                x
                y
                type
                availabilityType
                maxAmountOfPeople
                zonePoints
                color
                Employee {
                    ...MainEmployeeFields
                }

                Department {
                    name
                }

                Tags {
                    items {
                        Tag {
                            id
                            name
                        }
                    }
                }

                ReservableToDepartments {
                    items {
                        Department {
                            id
                            name
                        }
                    }
                }

                availabilityByDatesAndTime(
                    bookingRepeatType: $bookingRepeatType
                    employees: $employee
                    dates: $dates
                    daysOfWeek: $daysOfWeek
                    startTime: $startTime
                    endTime: $endTime
                )
                BookingReservablesByCreatedAt(filter: $parkingFilter, limit: 1000) {
                    items {
                        booking {
                            startTime
                            endTime
                            isFullDay
                            isBlocked
                            employeeID
                            Employee {
                                ...MainEmployeeFields
                            }
                        }
                    }
                }
            }
        }
    }
`

export const QUERY_SCHEDULE_SPACE_DATA_ROOM = gql`
    ${MAIN_EMPLOYEE}
    query ListRoomsBySpaceID(
        $id: ID!
        $datesPreview: [AWSDate]
        $type: ModelStringKeyConditionInput
        $filterByUser: ModelReservableFilterInput
    ) {
        listReservablesBySpaceAndType(spaceID: $id, filter: $filterByUser, type: $type, limit: 1000) {
            items {
                id
                name
                x
                y
                type
                availabilityType
                availabilityPreviewByDates(dates: $datesPreview)
                maxAmountOfPeople
                zonePoints
                color

                Employee {
                    ...MainEmployeeFields
                }

                Department {
                    name
                }

                ReservableToDepartments {
                    items {
                        Department {
                            id
                            name
                        }
                    }
                }
                Tags {
                    items {
                        Tag {
                            id
                            name
                        }
                        reservableID
                    }
                }
            }
        }
    }
`

export const QUERY_SCHEDULE_OFFICES = gql`
    query ListOffices($companyID: ID!, $employeeID: ID!, $date: AWSDate) {
        listOfficesByCompanyAndOrder(companyID: $companyID, limit: 1000) {
            items {
                id
                workingFrom
                workingTo
                SpaceRows {
                    items {
                        id
                        Spaces {
                            items {
                                id
                                name
                                isAvailableToEmployees(employeeIDs: [$employeeID], date: $date)
                                floorPlanFilePath
                                workingHoursFrom
                                workingHoursTo
                                totalSeats
                                availableParkingSlots
                                totalRooms
                                officeID
                                Office {
                                    id
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export const QUERY_SEAT_PICKER_SUGGESTED_SEATS = gql`
    query GetSuggestedSeatsForCalendar($employeeID: ID!, $date: String!) {
        getEmployee(id: $employeeID) {
            SuggestedSeats(date: { eq: $date }, filter: { spaceID: { attributeExists: false } }) {
                items {
                    id
                    createdAt
                    updatedAt
                    spaceID
                    Reservable {
                        id
                        spaceID
                        Space {
                            id
                            floorPlanFilePath
                            workingHoursFrom
                            workingHoursTo
                            name
                            officeID
                            Office {
                                id
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`

export const QUERY_GET_TEAMMATES_EMPLOYEES = gql`
    ${BOOKING}
    query GetTeammatesEmployees(
        $filter: SearchableEmployeeFilterInput
        $limit: Int
        $nextToken: String
        $sortName: SearchableSortDirection
        $startTime: String
        $endTime: String
    ) {
        searchEmployees(
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sort: { field: fullNameLowerCase, direction: $sortName }
        ) {
            items {
                id
                companyID
                departmentIDs
                positionID
                firstname
                lastname
                fullName
                email
                photo
                Position {
                    id
                    name
                }

                BookingsByStartTime(startTime: { ge: $startTime }, filter: { endTime: { le: $endTime } }, limit: 1000) {
                    items {
                        ...BookingFieldsForCalendar
                    }
                }

                status
                role
            }
            nextToken
            total
        }
    }
`

export const QUERY_GET_RESERVABLE_AVAILABILITY = gql`
    ${MAIN_EMPLOYEE}
    query GetReservableWithBookingsForAvailability(
        $reservableID: ID!
        $dateFilter: ModelBookingByReservableAndDateAndDayOfWeekCompositeKeyConditionInput
        $filter: ModelBookingFilterInput
    ) {
        getReservable(id: $reservableID) {
            BookingsByDateAndDayOfWeek(dateDayOfWeek: $dateFilter, filter: $filter, limit: 1000) {
                items {
                    date
                    id
                    Employee {
                        ...MainEmployeeFields
                    }
                    startBookingTime
                    endBookingTime
                }
            }
        }
    }
`

export const GET_DASHBOARD_ANALIICS_BY_OFFICE_AND_WEEK = gql`
    query GetDashboardAnaliticsByOfficeAndWeek(
        $officeID: ID!
        $weekNumberDate: ModelOfficeDayAggregationByOfficeAndWeekNumberAndDateCompositeKeyConditionInput!
    ) {
        listDashboardWeekAggregationByOfficeAndWeekNumberAndDate(
            officeID: $officeID
            weekNumberDate: $weekNumberDate
            filter: { type: { eq: SEAT } }
            limit: 1000
        ) {
            items {
                id
                bookedHours
                capacityInHours
                homeBookingsSum
                officeCapacityLast
                officeBookingsSum
                weekNumber
                year
                companyID
                date
            }
        }
    }
`

export const GET_DASHBOARD_LIST_DEPARTMENT_DAY_AGGREGATION = gql`
    query GetDashboardListDepartmentDayAggregation(
        $companyID: ID!
        $yearWeekNumberDate: ModelDepartmentDayAggregationByCompanyAndYearAndWeekNumberAndDateCompositeKeyConditionInput!
        $filter: ModelDepartmentDayAggregationFilterInput
    ) {
        listDepartmentDayAggregationByCompany(
            companyID: $companyID
            yearWeekNumberDate: $yearWeekNumberDate
            filter: $filter
            limit: 1000
        ) {
            items {
                officeBookingsSum
                date
                departmentID
                employeesInOffice {
                    items {
                        employee {
                            firstname
                            lastname
                            id
                            photo
                            fullName
                        }
                    }
                }
            }
        }
    }
`

import React, { useMemo, FC, useEffect, useState, memo, useCallback } from 'react'

import { useQuery } from '@apollo/client'
import moment from 'moment'
import { createContext, useContext } from 'use-context-selector'

import { useMsTeams } from 'context/msTeams'
import { QUERY_GET_COMPANY } from 'graphql/queries'
import {
    SUBSCRIPTION_COMPANY_RULES_UPDATE,
    SUBSCRIPTION_COMPANY_TARIFF_UPDATE,
    SUBSCRIPTION_UPDATE_USER,
} from 'graphql/subscriptions'
import { noop } from 'helpers'
import { useReconnectingSubscription } from 'helpers/useReconnectingSubscription'
import { useAppDispatch } from 'hooks'
import { useData } from 'hooks/useData'
import { messageActions } from 'store/slices/message'
import { userActions } from 'store/slices/user'
import { TARIFF } from 'types/billing.types'
import { TCompany, TDepartment, TEmployee, TPosition, TReservable, TRules, TSpace } from 'types/data.types'
import { EUserGroup, UserResponse } from 'types/user.types'

import { useAuth } from './auth'
import { usePreloader } from './preloader'
import i18n from '../i18n'

require('moment/locale/de')
require('moment/locale/fr')

export const CompanyContext = createContext<{
    loading?: boolean
    company?: TCompany
    update: () => void
    spaces: TSpace[]
    employees: Omit<TEmployee, 'Bookings' | 'Company'>[]
    employeesAll: Omit<TEmployee, 'Bookings' | 'Company'>[]
    departments: TDepartment[]
    positions: TPosition[]
    reservableCreate: (res: TReservable) => void
    changeTariff: (tariff: TARIFF) => void
}>({
    update: noop,
    spaces: [],
    employees: [],
    employeesAll: [],
    departments: [],
    positions: [],
    reservableCreate: noop,
    changeTariff: noop,
})

//todo: add good typing
export const CompanyProvider: FC<{ children: React.ReactNode }> = memo(({ children }) => {
    const { userAuth, refreshJWTToken, userGroup } = useAuth()
    const { showPreloader } = usePreloader()
    const { context, inTeams } = useMsTeams()
    const dispatch = useAppDispatch()

    const {
        spaces,
        employees: employeesAll,
        employeesRegistered: employees,
        departments,
        positions,
        reservableCreate,
    } = useData()

    const [company, setCompany] = useState<TCompany>()
    const [firstStart, setFirstStart] = useState(true)

    const {
        loading,
        data: dataCompany,
        refetch,
    } = useQuery<{ getCompany: TCompany; getEmployee: UserResponse }>(QUERY_GET_COMPANY, {
        variables: { id: userAuth?.companyId, employeeId: userAuth?.employeeId },
        onError: (err) => dispatch(messageActions.messageShown({ text: err.message, severity: 'error' })),
    })

    useReconnectingSubscription<
        { onCompanyTariffUpdate: { companyId: string; tariff: TARIFF } },
        { companyId: string }
    >(SUBSCRIPTION_COMPANY_TARIFF_UPDATE, {
        skip: userGroup === EUserGroup.MANAGER || !userAuth?.companyId,
        variables: { companyId: userAuth?.companyId || '' },
        onData: async ({ data: { data } }) => {
            if (data && data.onCompanyTariffUpdate.companyId === userAuth?.companyId) {
                changeTariff(data.onCompanyTariffUpdate.tariff)
                await refreshJWTToken()
            }
        },
    })

    useReconnectingSubscription<{ onUpdateRules: TRules }, { companyId: string }>(SUBSCRIPTION_COMPANY_RULES_UPDATE, {
        variables: { companyId: userAuth?.companyId || '' },
        skip: !userAuth?.companyId,
        onData: async ({ data: { data } }) => {
            if (data?.onUpdateRules && company) {
                setCompany({
                    ...company,
                    Rules: data.onUpdateRules,
                })
            }
        },
    })

    useReconnectingSubscription<{ onUpdateEmployeeById: UserResponse }>(SUBSCRIPTION_UPDATE_USER, {
        variables: { companyId: userAuth?.companyId, id: userAuth?.employeeId },
        skip: !userAuth?.companyId || !userAuth?.employeeId,
        onData: ({ data: { data } }) => {
            const updatedData = data?.onUpdateEmployeeById
            if (updatedData !== undefined) {
                dispatch(userActions.userDataReceived(updatedData))
            }
        },
    })

    const changeTariff = useCallback((tariff: TARIFF) => {
        setCompany((prevState) => (prevState === undefined ? undefined : { ...prevState, tariff }))
    }, [])

    const update = useCallback(async () => {
        const newData = await refetch()

        if (newData?.data?.getCompany) {
            setCompany(newData?.data?.getCompany)
        }
    }, [refetch])

    useEffect(() => {
        if (dataCompany) {
            setCompany({
                ...dataCompany.getCompany,
                tariff: dataCompany.getCompany.tariff || TARIFF.FREE,
            })

            const employee = dataCompany.getEmployee

            dispatch(userActions.userDataReceived(employee))

            if (!inTeams) {
                i18n.changeLanguage(dataCompany.getEmployee.locale)
                moment.locale(dataCompany.getEmployee.locale || 'en')
            }
        }

        if (loading && firstStart) {
            showPreloader(true)
            setFirstStart(false)
        } else {
            showPreloader(false)
        }

        if (inTeams && context?.app) {
            const languageId = context.app.locale.split('-')[0]
            console.log('[YOFFIX] languageId', languageId)

            if (['de', 'fr'].includes(languageId)) {
                i18n.changeLanguage(languageId)
                moment.locale(languageId)
            } else {
                i18n.changeLanguage('en')
                moment.locale('en')
            }
        }
    }, [loading])

    const contextValue = useMemo(
        () => ({
            loading,
            company,
            update,
            spaces,
            employees,
            employeesAll,
            departments,
            positions,
            reservableCreate,
            changeTariff,
        }),
        [
            loading,
            company,
            update,
            spaces,
            employees,
            employeesAll,
            departments,
            positions,
            reservableCreate,
            changeTariff,
        ],
    )

    return <CompanyContext.Provider value={contextValue}>{children}</CompanyContext.Provider>
})

export const useCompanyData = () => useContext(CompanyContext)

import { gql } from '@apollo/client'

import {
    BOOKING,
    DEPARTMENT,
    EMPLOYEE,
    OFFICE,
    PERSONIO,
    POSITION,
    RESERVABLE,
    RULES,
    SLACK,
    SPACE,
    CALENDAR_EVENT,
    BAMBOOHR,
} from './dataQuery'

export const MUTATION_UPDATE_COMPANY = gql`
    ${RULES}
    mutation UpdateCompany($input: UpdateCompanyInput!) {
        updateCompany(input: $input) {
            id
            title
            country
            city
            address
            zipcode
            phoneNumber
            numberOfOffices
            numberOfEmployees
            companyLogo
            tariff
            Rules {
                ...RulesFields
            }
            Offices {
                items {
                    id
                    name
                }
            }
        }
    }
`

export const MUTATION_CREATE_EMPLOYEE = gql`
    ${EMPLOYEE}
    ${POSITION}
    ${OFFICE}
    ${DEPARTMENT}
    mutation CreateEmployee($input: CreateEmployeeInput!) {
        createEmployee(input: $input) {
            ...EmployeeFields
            Position {
                ...PositionFields
            }
            EmployeeToDepartment {
                items {
                    Department {
                        ...DepartmentFields
                    }
                }
            }
            Office {
                ...OfficeFields
            }
        }
    }
`
export const MUTATION_UPDATE_EMPLOYEE = gql`
    ${EMPLOYEE}
    ${POSITION}
    ${OFFICE}
    ${DEPARTMENT}
    ${BOOKING}
    ${RESERVABLE}
    mutation UpdateEmployee($input: UpdateEmployeeInput!) {
        updateEmployee(input: $input) {
            cognitoUserId
            ...EmployeeFields
            EmployeeToDepartment {
                items {
                    Department {
                        ...DepartmentFields
                    }
                }
            }
            Position {
                ...PositionFields
            }
            Office {
                ...OfficeFields
            }
            favouriteOfficeID
            Bookings {
                items {
                    ...BookingFields
                    reservable {
                        ...ReservableFields
                    }
                }
            }
        }
    }
`
export const MUTATION_DELETE_EMPLOYEE = gql`
    ${EMPLOYEE}
    ${POSITION}
    ${OFFICE}
    ${BOOKING}
    ${RESERVABLE}
    mutation DeleteEmployee($input: DeleteEmployeeInput!) {
        deleteEmployee(input: $input) {
            ...EmployeeFields
            Position {
                ...PositionFields
            }
            Office {
                ...OfficeFields
            }
            Bookings {
                items {
                    ...BookingFields
                    reservable {
                        ...ReservableFields
                    }
                }
            }
        }
    }
`

export const MUTATION_ALL_DELETE_EMPLOYEE = gql`
    ${EMPLOYEE}
    ${POSITION}
    mutation AllDeleteEmployee($employeeIDs: [ID!]!) {
        deleteEmployees(employeeIDs: $employeeIDs) {
            employee {
                ...EmployeeFields
                Position {
                    ...PositionFields
                }
            }
            error
        }
    }
`

export const MUTATION_INVITE_EMPLOYEES = gql`
    ${EMPLOYEE}
    ${POSITION}
    mutation InviteEmployees($employeeIDs: [ID!]!) {
        inviteEmployees(employeeIDs: $employeeIDs) {
            employee {
                ...EmployeeFields
                Position {
                    ...PositionFields
                }
            }
            error
        }
    }
`

export const MUTATION_CREATE_OFFICE = gql`
    mutation CreateOffice($input: CreateOfficeInput!) {
        createOffice(input: $input) {
            id
            companyID
            workingFrom
            workingTo
            timeZone
        }
    }
`

export const MUTATION_UPDATE_OFFICE = gql`
    mutation UpdateOffice($input: UpdateOfficeInput!) {
        updateOffice(input: $input) {
            id
            name
            workingFrom
            workingTo
            address
            companyID
        }
    }
`

export const MUTATION_DELETE_OFFICE = gql`
    mutation DeleteOffice($input: DeleteOfficeInput!) {
        deleteOffice(input: $input) {
            id
            companyID
            name
        }
    }
`

export const MUTATION_CREATE_DEPARTMENT = gql`
    ${EMPLOYEE}
    ${DEPARTMENT}
    mutation CreateDepartment($input: CreateDepartmentInput!) {
        createDepartment(input: $input) {
            ...DepartmentFields
            DepartmentRules {
                blockReservationOnNonOfficeDays
                enableMinDaysInOffice
                minDaysInOffice
                officeDays
                minDaysInOfficeTimeUnit
            }
            TeamLead {
                ...EmployeeFields
            }
            EmployeeToDepartment {
                items {
                    id
                    Employee {
                        ...EmployeeFields
                    }
                }
            }
        }
    }
`
export const MUTATION_CREATE_DEPARTMENT_RULES = gql`
    mutation CreateDepartmentRules($input: CreateDepartmentRulesInput!) {
        createDepartmentRules(input: $input) {
            minDaysInOffice
            enableMinDaysInOffice
            departmentID
            id
            officeDays
            horizonOfPlanningTimeUnit
            horizonOfPlanningTimeAmount
        }
    }
`

export const MUTATION_UPDATE_DEPARTMENT_RULES = gql`
    mutation UpdateDepartRules($input: UpdateDepartmentRulesInput!) {
        updateDepartmentRules(input: $input) {
            minDaysInOffice
            enableMinDaysInOffice
            departmentID
            id
            officeDays
            horizonOfPlanningTimeUnit
            horizonOfPlanningTimeAmount
        }
    }
`

export const MUTATION_UPDATE_DEPARTMENT = gql`
    ${EMPLOYEE}
    ${DEPARTMENT}
    mutation UpdateDepartment($input: UpdateDepartmentInput!) {
        updateDepartment(input: $input) {
            ...DepartmentFields
            DepartmentRules {
                blockReservationOnNonOfficeDays
                enableMinDaysInOffice
                minDaysInOffice
                officeDays
                minDaysInOfficeTimeUnit
            }
            TeamLead {
                ...EmployeeFields
            }
            EmployeeToDepartment {
                items {
                    id
                    Employee {
                        ...EmployeeFields
                    }
                }
            }
        }
    }
`

export const MUTATION_DELETE_DEPARTMENT = gql`
    ${DEPARTMENT}
    mutation DeleteDepartment($input: DeleteDepartmentInput!) {
        deleteDepartment(input: $input) {
            ...DepartmentFields
        }
    }
`
export const MUTATION_CREATE_POSITION = gql`
    ${POSITION}
    mutation CreatePosition($input: CreatePositionInput!) {
        createPosition(input: $input) {
            ...PositionFields
        }
    }
`

export const MUTATION_UDTATE_POSITION = gql`
    ${POSITION}
    mutation UpdatePosition($input: UpdatePositionInput!) {
        updatePosition(input: $input) {
            ...PositionFields
        }
    }
`

export const MUTATION_DELETE_POSITION = gql`
    ${POSITION}
    mutation DeletePosition($input: DeletePositionInput!) {
        deletePosition(input: $input) {
            ...PositionFields
        }
    }
`

export const MUTATION_CREATE_SPACE = gql`
    ${SPACE}
    ${RESERVABLE}
    ${EMPLOYEE}
    ${POSITION}
    ${DEPARTMENT}
    mutation CreateSpace($input: CreateSpaceInput!) {
        createSpace(input: $input) {
            ...SpaceFields
            Office {
                name
                id
            }
            Reservables {
                items {
                    ...ReservableFields
                    Employee {
                        ...EmployeeFields
                        Position {
                            ...PositionFields
                        }
                    }
                    Department {
                        ...DepartmentFields
                    }
                }
            }
        }
    }
`

export const MUTATION_UPDATE_SPACE = gql`
    ${SPACE}
    ${RESERVABLE}
    ${EMPLOYEE}
    ${POSITION}
    ${DEPARTMENT}
    mutation UpdateSpace($input: UpdateSpaceInput!) {
        updateSpace(input: $input) {
            ...SpaceFields
            Office {
                name
                id
            }
            Reservables {
                items {
                    ...ReservableFields
                    Employee {
                        ...EmployeeFields
                        Position {
                            ...PositionFields
                        }
                    }
                    Department {
                        ...DepartmentFields
                    }
                }
            }
        }
    }
`

export const MUTATION_CREATE_RESERVABLE = gql`
    ${RESERVABLE}
    ${EMPLOYEE}
    ${POSITION}
    ${DEPARTMENT}
    mutation CreateReservable($input: CreateReservableInput!) {
        createReservable(input: $input) {
            ...ReservableFields
            Employee {
                ...EmployeeFields
                Position {
                    ...PositionFields
                }
            }
            Department {
                ...DepartmentFields
            }
        }
    }
`

export const MUTATION_CREATE_TO_EMPLOYEE = gql`
    ${EMPLOYEE}
    mutation CreateReservableToEmployee($input: CreateReservableToEmployeeInput!) {
        createReservableToEmployee(input: $input) {
            id
            employeeID
            employee {
                ...EmployeeFields
            }
        }
    }
`

export const MUTATION_CREATE_TO_DEPARTMENT = gql`
    mutation CreateReservableToDepartment($input: CreateReservableToDepartmentInput!) {
        createReservableToDepartment(input: $input) {
            id
            departmentID
            Department {
                id
                name
            }
        }
    }
`

export const MUTATION_DELETE_TO_EMPLOYEE = gql`
    mutation DeleteReservableToEmployee($input: DeleteReservableToEmployeeInput!) {
        deleteReservableToEmployee(input: $input) {
            id
        }
    }
`

export const MUTATION_DELETE_TO_DEPARTMENT = gql`
    mutation DeleteReservableToDepartment($input: DeleteReservableToDepartmentInput!) {
        deleteReservableToDepartment(input: $input) {
            id
        }
    }
`

export const MUTATION_UPDATE_RESERVABLE = gql`
    ${RESERVABLE}
    ${EMPLOYEE}
    ${POSITION}
    ${DEPARTMENT}
    mutation UpdateReservable($input: UpdateReservableInput!) {
        updateReservable(input: $input) {
            ...ReservableFields
            Employee {
                ...EmployeeFields
                Position {
                    ...PositionFields
                }
            }
            Department {
                ...DepartmentFields
            }
            admin {
                ...EmployeeFields
            }
        }
    }
`

export const MUTATION_DELETE_RESERVABLE = gql`
    ${RESERVABLE}
    ${EMPLOYEE}
    ${POSITION}
    ${DEPARTMENT}
    mutation DeleteReservable($input: DeleteReservableInput!) {
        deleteReservable(input: $input) {
            ...ReservableFields
            Employee {
                ...EmployeeFields
                Position {
                    ...PositionFields
                }
            }
            Department {
                ...DepartmentFields
            }
        }
    }
`

export const MUTATION_BOOKING_CANCELATION = gql`
    mutation CreateBookingCancellation($input: CreateBookingCancellationInput!) {
        createBookingCancellation(input: $input) {
            id
        }
    }
`

export const MUTATION_INIT_PERSONIO_INTEGRATION = gql`
    ${PERSONIO}
    mutation InitialisePersonioIntegration($input: PersonioIntegrationInput!) {
        initialisePersonioIntegration(input: $input) {
            ...PersonioFields
        }
    }
`

export const MUTATION_DISABLE_PERSONIO_INTEGRATION = gql`
    ${PERSONIO}
    mutation DisablePersonioIntegration {
        disablePersonioIntegration {
            ...PersonioFields
        }
    }
`

export const MUTATION_FORCE_SYNC_PERSONIO_INTEGRATION = gql`
    ${PERSONIO}
    mutation ForceSyncPersonioIntegration {
        forceSyncPersonioIntegration {
            ...PersonioFields
        }
    }
`

export const MUTATION_UPDATE_PERSONIO_INTEGRATION = gql`
    ${PERSONIO}
    mutation UpdatePersonioIntegration($input: UpdatePersonioIntegrationInput!) {
        updatePersonioIntegration(input: $input) {
            ...PersonioFields
        }
    }
`

export const MUTATION_UPDATE_SLACK_INTEGRATION = gql`
    ${SLACK}
    mutation UpdateSlackIntegration($input: SlackIntegrationUpdateInput!) {
        updateSlackIntegration(input: $input) {
            ...SlackFields
        }
    }
`

export const MUTATION_INIT_BAMBOOHR_INTEGRATION = gql`
    ${BAMBOOHR}
    mutation InitiateBambooHRIntegration($input: BambooHRIntegrationInput!) {
        initiateBambooHRIntegration(input: $input) {
            ...BambooHRFields
        }
    }
`

export const MUTATION_TOOGLE_INVITE_BAMBOOHR_INTEGRATION = gql`
    mutation ToggleInvitationForBambooHRIntegration {
        toggleInvitationForBambooHRIntegration
    }
`

export const MUTATION_DELETE_BAMBOOHR_INTEGRATION = gql`
    mutation DeleteBambooHRIntegration {
        deleteBambooHRIntegration
    }
`

export const MUTATION_DISABLE_SLACK_INTEGRATION = gql`
    ${SLACK}
    mutation DeleteSlackIntegration {
        deleteSlackIntegration {
            ...SlackFields
        }
    }
`

export const MUTATION_START_TRIAL = gql`
    mutation StartTrial($subscriptionPlanId: String!) {
        startTrial(subscriptionPlanId: $subscriptionPlanId)
    }
`

export const MUTATION_UPDATE_RULES = gql`
    ${RULES}
    mutation UpdateRules($input: UpdateRulesInput!) {
        updateRules(input: $input) {
            ...RulesFields
        }
    }
`

export const MUTATION_CREATE_CALENDAR_EVENT = gql`
    ${CALENDAR_EVENT}
    mutation CreateCalendarEvents($input: CreateCalendarEventsInput!) {
        createCalendarEvents(input: $input) {
            ...CalendarEventFields
        }
    }
`

export const MUTATION_CREATE_TEAM_EVENT = gql`
    mutation CreateTeamEventRequest($input: CreateTeamEventRequestInput!) {
        createTeamEventRequest(input: $input) {
            id
            date
            title
            description
            reservableID
            spaceID
            companyID
            repeatType
        }
    }
`

export const MUTATION_UPDATE_CALENDAR_EVENT = gql`
    ${CALENDAR_EVENT}
    mutation UpdateCalendarEvents($input: UpdateCalendarEventsInput!) {
        updateCalendarEvents(input: $input) {
            ...CalendarEventFields
        }
    }
`

export const MUTATION_DELETE_CALENDAR_EVENT = gql`
    ${CALENDAR_EVENT}
    mutation DeleteCalendarEvents($input: DeleteCalendarEventsInput!) {
        deleteCalendarEvents(input: $input) {
            ...CalendarEventFields
        }
    }
`

export const MUTATION_CREATE_OFFICE_BUILDER = gql`
    mutation CreateOfficeBuilder($input: CreateOfficeBuilderRequestInput!) {
        createOfficeBuilderRequest(input: $input) {
            id
            companyID
        }
    }
`

export const MUTATION_DELETE_SPACE = gql`
    mutation DeleteSpace($input: DeleteSpaceInput!) {
        deleteSpace(input: $input) {
            id
            name
            companyID
        }
    }
`
